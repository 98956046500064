.card-margin-top {
  margin-top: 30px;
}

.bg-color-blue {
  background-color: #f8f8ff;
  border-radius: 10px;
}

.bg-table-blue{
  background-color:#f0f8ffa3;
}