.thick-nav  {
  padding: .5rem 1rem !important;
}

.navbar-brand {
  color:white !important;
  font-weight: bold;
  font-size: 1.0rem !important;
  text-align: left !important;
  font-family: Comic Sans MS;
}

.nav-link {
  color:white !important;
  font-weight: bold;
}

.black {
  color:black !important;
  font-weight: normal;
}

.active{
  color:rgb(255, 209, 191);
  border-bottom-style: solid;
  border-bottom-color: white;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.nav-item{
  margin:5px;
}