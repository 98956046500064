.about-us {

    font-family: Apple Chancery;
    color: black;
    font-size: 24 px;
   

}

.reviews-bg{
  background-color: #ffe167c9;
}

.background-about-us {
    background-color:#efffe4cf !important;
  }
  .progress {
    height:1.5rem !important;
  }

  .comment-section {
    border-left: #8856d2;
        border-left-style: none;
        border-left-width: medium;
    border-left-style: solid;
    border-left-width: 5px;
    background-color: #f5f7f5;
    text-align: left;

  }

  .comment-text{
  padding-left: 20px;
  font-size: 18px;
  }

  .comment-footer {
    text-align: right;
    color:gray;
  }
.review{
  max-height:400px;
  overflow-y: auto;
}

.contact-color {
  color:#9f0000;
}