.card-img-top {
  width:30% !important;
}

.media{
  border-color: #ff5151 white #0fc109 white;
  border-style: solid;
  background: honeydew;
  text-align: left;
}

.media-heading {
  color: blueviolet;
}

.card-shadow{
  -webkit-box-shadow: 10px 10px 13px -6px rgba(150,255,171,1);
  -moz-box-shadow: 10px 10px 13px -6px rgba(150,255,171,1);
  box-shadow: 10px 10px 13px -6px rgba(150,255,171,1);
  min-height: 400px;
}