.newHeaderBg {
    background: url('../ImageConstants/Images/aleksandrkozlovskii.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height:700px;
  }

  .headTitle{
      font-size: 7em;
      color:white;
      font-family: 'Quantico';
  }

  .tagLine{
    font-size: 2em;
    color:rgb(255, 213, 166);
    font-family: 'Quantico';
    font-style: italic;
    text-align: right;
    font-weight: bolder;
  }

  .toast-color {
    background-color: rgb(255, 227, 184) !important;
  }
.toast-body{
  color: #740101;
    font-weight: bolder;
}
.toast-header{
  background-color:  rgb(255, 189, 116) !important;
  color: black !important;
}

.toast{
  max-width: 450px !important;
}