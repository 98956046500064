.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color:black !important;
    background-color: aliceblue !important;
    border-bottom-color: green  !important;
    border-bottom-style: solid  !important;
    border-bottom-width: thick !important;
}

.nav-tabs .nav-link {
  color:#8e8e8e !important;
  background-color: aliceblue !important;
  border-bottom-color: #9aec91 !important;
  border-bottom-style: solid !important;
  border-bottom-width: thick !important;
}

.hidden{
  visibility: hidden;
}

.visible{
  visibility: visible;
}
