.footer{
    background: rgb(238,217,70);
    background: linear-gradient(347deg,  rgba(255,132,0,1) 15%, rgba(241,241,241,1) 16%, rgba(255,255,255,1) 82%, rgba(0,0,0,1) 84%);
    color:black;
    font-weight: 300;
    width: 100%;
}
a{
    font-weight:bold;
}
address {
    font-weight: bold;
}