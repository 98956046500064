@media (max-height: 1000px)
.blackbg {
  min-height: 250px;
  background-color: black;

}

h1 {
  font-size: 3.5rem !important;
}

.center-text {
  align-content: center;
  text-align:center;
  background-color:Black;
  font-weight: bolder;
  color:orange;
}

.blackbg {
  min-height: 500px;
  background-color: black;


}

.image-row{
  min-height: 350px;
  margin: 0px;
  padding: 0px;
  background-color:white ;
}

.image-bg{
  min-height: 350px;
  background-size: 600px 350px;
  background-image: url('../ImageConstants/Images/training.jpg');
  background-repeat: no-repeat;

}

.text-color-1{
  color:orange;
  font-family: 'Comic Sans MS';
  text-align: center;
  font-style: italic;
  font-size: 80px;
}
.text-color-2{
  color:#ff751a;
  font-weight: bold;
}

.text-color-3{
  color:green;
  font-weight: bold;
}
.text-color-4{
  color:brown;
  font-weight: bold;
}
.text-color-5{
  color:blue;
  font-weight: bold;
}
.text-color-6{
  color:purple;
  font-weight: bold;
}
.text-color-7{
  color:red;
  font-weight: bold;
}

.text-white{
  color:white !important;
}
.text-black{
  color:black !important;
}

.sub-text-green{
  text-align: left;
  align-content: left;
  font-family: Arial;
  font-style: normal;
  margin-left:5px;
  padding-left: 10px;
  background-color: green;
  
}
.sub-text-orange{
  text-align: left;
  align-content: left;
  font-family: Arial;
  font-style: normal;
  margin-left:5px;
  padding-left: 10px;
  background-color: #ff8502;
}
.sub-text{
  text-align: left;
  align-content: left;
  font-family: Arial;
  font-style: normal;
  margin-left:5px;
  padding-left: 10px;
  background-color:white ;
  margin-bottom: 5px;
}

.fa-green{
    color:chartreuse;
}

.small-text{
  font-size:30px;
}
.text-color-white{
  color:white;
  font-size:25px;
  font-family: 'Comic Sans MS';
  text-align: center;
  font-style: italic;
  font-weight: normal;
}

.text-color-red{
  color:#ff6700;
  font-weight: bold;
  text-align: left;
  font-size: 55px;
}

.text-color-yellow{
  color:#6cf542;
  font-size: 45px;

}

.left-align{
  text-align: left;
}
.right-align{
  text-align: right;
}

.font-big{
  font-size: 35px;
}

.font-size-bigger{
  font-size: 55px;
  font-weight: normal;
  color:#ff6700;
  font-style: italic;
  font-family: 'Comic Sans MS';
}

.align-right{
  text-align: right;
}

.align-left{
  text-align: left;
}
.text-color-orange{
  color:#ff6700;
  font-style: italic;
  text-align: left;
  font-size: 25px;
  font-family: 'Comic Sans MS';
}

.align-center{
  text-align: center;
}

.img-car{
  background-image: url('../ImageConstants/Images/auto_car-06.jpg');
}