.navbar-bg  {
  background-color: rgb(49, 189, 84);
  position: sticky;
  top:0px;
  left:0px;
  z-index:1;
}
.left-align{

  text-align: left;
}

.background-gray{
  background-color: gray;
}

.background-green{
  background-color:#b4dfffed;
}


.seperator{
background-image: linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet);
height: 5px;
}


.white-seperator{
background-color:#5e0a0a;
height: 5px;
}

.about-us {

    font-family: Apple Chancery;
    color: black;
    font-size: 24px;

}

.background-about-us {
    background-color: #bac8ff;
  }

.width-70{
  width: 70%;
}



.footer-black  {
  background-color:#9d9d9d;
}

.bg-dark{
  background-color: black !important;
}