.about-us-page{
    text-align: left;
    font: Apple Chancery;
    background-color: rgba(240, 255, 255, 0.938);
    border-radius: 10px;
    font-size: 22px;
    padding:10px 10px 10px 10px;
    -webkit-box-shadow: 1px 3px 13px 12px rgba(109,219,72,0.49);
    -moz-box-shadow: 1px 3px 13px 12px rgba(109,219,72,0.49);
    box-shadow: 1px 3px 13px 12px rgba(109,219,72,0.49);
}

.contact-us-page{
    text-align: left;
    font: Apple Chancery;
    background-color: rgba(240, 255, 255, 0.938);
    border-radius: 10px;
    font-size: 18px;
    padding:10px 10px 10px 10px;
    -webkit-box-shadow: 1px 3px 13px 12px rgba(109,219,72,0.49);
    -moz-box-shadow: 1px 3px 13px 12px rgba(109,219,72,0.49);
    box-shadow: 1px 3px 13px 12px rgba(109,219,72,0.49);
}

.heading{
    color:red;
    font-weight: bolder;
}
.highlight-red{
    font-size: x-large;
    color:purple;
    font-weight: bold;
}
.highlight-blue{
    font-weight: bold;
    color:brown;
}

.gallery{
    padding:10px 10px 10px 10px;
    background-color: rgba(240, 255, 255, 0.938);
    border-radius: 10px;
    -webkit-box-shadow: 1px 3px 13px 12px rgba(109,219,72,0.49);
    -moz-box-shadow: 1px 3px 13px 12px rgba(109,219,72,0.49);
    box-shadow: 1px 3px 13px 12px rgba(109,219,72,0.49);
}